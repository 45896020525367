<template>
  <!-- 즐겨찾기한 코치 -->
  <coach-my-page-content-layout>
    UserLikedCoaches
  </coach-my-page-content-layout>
</template>

<script>
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout';

export default {
  name: 'UserLikedCoaches',
  components: { CoachMyPageContentLayout },
};
</script>
